<template>
  <page-content :show-search="true">
    <template #search>
      <table-search
        ref="search"
        v-model="filterValue"
        :fields="search_fields"
        @runSearch="runSearch"
        @changeField="searchChangeField"
      />
    </template>

    <template #default>
      <table-header
        ref="header"
        :title="title"
        :button-actions="header_actions"
        @runSearch="runSearch"
        search-tips="搜尋 型號標識 / 名稱 / 品牌 / 型號"
      />

      <table-content
        :per-page="page_condition.pageSize"
        :per-page-dropdown-enabled="true"
        @perPageChange="perPageChange"
        :columns="columns"
        :rows="rows"
        :total-rows="total_rows"
        :current-page="current_page"
        :action-options="table_actions"
        @runTableAction="runTableAction"
        @sortChange="sortChange"
        @pageChange="pageChange"
        :permission="view_permission"
      />
    </template>

  </page-content>
</template>

<script>
import common from '@/common'
import kioskUtils from '@/libs/kiosk-utils'
import TableContent from '@/components/TableContent'
import TableHeader from '@/components/TableHeader'
import TableSearch from '@/components/TableSearch'
import PageContent from '@/components/PageContent'

export default {
  components: {
    PageContent,
    TableSearch,
    TableHeader,
    TableContent,
  },
  name: 'kioskPeripheralModel',
  data() {
    return {
      title: common.getMenuName('kioskPeripheralModel'),
      view_permission: common.checkPermission('Kiosk_Peripheral_Model_List'),
      // view_permission: true,
      rows: [],
      total_rows: 0,
      sort_condition: {},
      current_page: 1,
      page_condition: {
        pageIndex: 0,
        pageSize: common.getTablePerPage()
      },
      status_map: [],
      header_actions: [
        {
          text: 'common.add',
          variant: 'primary',
          fun: this.addData,
          // permission: 'Kiosk_Menu_Installer'
        },
      ],
      columns: [
        {
          label: 'common.row_index',
          field: 'row_index',
          sortable: false,
          width: '50px',
        },
        {
          label: '型號標識',
          field: 'bleName',
          width: '150px',
        },
        {
          label: '類型',
          field: 'purposes',
          width: '130px',
          changeHTML: (raw, _row) => {
            return raw.map(val => `<span class="badge badge-light-secondary wh-font-family-monospace mr-05 font-weight-normal">${kioskUtils.purposeOpts.find(p => p.value === val)?.text ?? ''}</span>`)
              .join('')
          }
        },
        {
          label: '名稱',
          field: 'name',
          width: '200px',
        },
        {
          label: '品牌',
          field: 'brand',
          width: '130px',
        },
        {
          label: '型號',
          field: 'model',
          width: '140px',
        },
        {
          label: '型號備註',
          field: 'modelRemark',
          // width: '320px',
        },
        {
          label: 'common.action',
          field: 'action',
          width: '180px',
          sortable: false,
        },
      ],
      table_actions: [
        {
          text: 'common.edit',
          variant: 'primary',
          fun: this.editData,
          // permission: 'Kiosk_Peripheral_Model_Edit'
        },
        {
          text: 'common.delete',
          variant: 'danger',
          fun: this.deleteData,
          permission: 'Kiosk_Peripheral_Model_Delete'
        }
      ],
      search_fields: [
        {
          field: 'purposeList',
          label: '類型',
          type: 'select',
          options: kioskUtils.purposeOpts,
          multiple: true,
        },
      ],
      filterValue: {
        purposeList: null,
      },
      purposes: kioskUtils.purposeOpts,
    }
  },
  activated() {
    this.getList()
  },
  deactivated() {
    this.$refs.search.resetFilterValueToFiltered()
  },

  methods: {
    getList: async function () {
      if (!this.view_permission) return

      // const api = '/kiosk/WeHealth/GetKioskPeripheralModelList' // deprecated
      const api = '/kioskmanage/GetDeviceModels'
      const search = this.getSearchCondition()
      const url = common.getTableUrl(api, search, this.sort_condition, this.page_condition)
      const { data } = await common.apiGetData(url)

      // Assign value to current component
      this.total_rows = data.total
      this.rows = data.models
    },
    perPageChange(size) {
      this.page_condition.pageIndex = 0
      this.page_condition.pageSize = size
      common.setTablePerPage(size)
      this.getList()
    },
    addData: function () {
      this.$router.push({ name: 'kioskPeripheralModel_add' })
    },
    editData: function (data) {
      this.$router.push({
        name: 'kioskPeripheralModel_edit',
        params: { id: common.encrypt(data.deviceModelID) }
      })
    },
    deleteData: function (data) {
      console.log(data)
      common.showConfirm({
        title: this.$t('common.delete_tips') + data.bleName,
        confirm_fun: async () => {
          await this.$requestWehealth({
            url: '/kioskmanage/DeleteDeviceModel',
            method: 'post',
            params: {
              modelID: data.deviceModelID
            }
          })
          common.showToast({ title: this.$t('common.delete_success') })
          this.getList()
        }
      })
    },

    runSearch: function () {
      this.current_page = 1
      this.page_condition.pageIndex = 0
      this.getList()
    },
    async searchChangeField(field, value) {
      await common.searchChangeField({
        fieldName: field,
        newValue: value,
        currentVm: this,
        searchVm: this.$refs.search,
        isSyncGlobally: false
      })
    },

    runTableAction: function (action, data) {
      if (typeof action === 'function') action(data)
      else this[action](data)
    },
    sortChange: function (value) {
      this.sort_condition = value
      this.getList()
    },
    getSearchCondition: function () {
      this.$refs.search.buildSearchCondition()

      let where = {}
      where['searchContent'] = this.$refs.header.searchContent
      where = Object.assign(where, this.$refs.search.search_condition)

      return where
    },
    pageChange: function (value) {
      this.current_page = value
      this.page_condition.pageIndex = value - 1
      this.getList()
    },
    changeStatus: function (value) {
      for (const i in this.status_map) {
        if (this.status_map[i].value === value) {
          return this.status_map[i].text
        }
      }
    },
  }
}
</script>

<style lang="scss">

</style>
